/*
 * @Author: your name
 * @Date: 2021-11-08 11:29:25
 * @LastEditTime: 2021-11-16 11:13:57
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \crm-product（西凤产品web）\found\views\sfa\views\reports\meeting_affair_charge\index.js
 */
import TablePage from '../../../../../components/table_page';
import Form from './form/index.js';
import scanDetails from './scan_details';

export default {
  name: 'table-component',
  extends: TablePage,
  data() {
    return {
      // searchFormData: {},
      // modalFormData: {}, // 弹出层绑定数据
      // formName: 'viewDetail',
    };
  },
  components: {
    Form,
    scanDetails,
  },
  methods: {
    modalClick({
      val,
      row,
    }) {
      const {
        code,
      } = val;
      console.log(val);
      console.log(row);
      if (code === 'view') {
        this.modalConfig.title = '查看';
        this.formName = 'Form';

        this.formConfig = {
          name: 'HWF',
          code,
          row,
        };
        // this.openDrawer();
        this.openFull();
      }
      if (code === 'scan_details') {
        this.modalConfig.title = '扫码明细';
        this.formName = 'scanDetails';
        this.propsObjInData.row = row;
        // this.openDrawer();
        this.openFull();
      }
    },
  },
  created() {
    this.getConfigList('meeting_affair_charge_report');
  },

};
