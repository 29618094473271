<template>
  <div class="img-wrap">
    <el-image
      v-for="(item, index) in imgList"
      :key="index"
      style="width: 100px; height: 100px; margin: 20px"
      :src="item"
      :preview-src-list="imgList"
    >
    </el-image>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value(newVal, oldVal) {
      const tempArr = [];
      for (const item of newVal) {
        if (item.photos) {
          item.photos.split(',').forEach((list) => {
            tempArr.push(list);
          });
        }
      }
      this.imgList = tempArr;
    },
  },
  data() {
    return {
      imgList: [],
    };
  },
};
</script>

<style scoped>
.img-wrap {
  display: flex;
  align-items: center;
  flex-flow: wrap;
  margin-bottom: 20px;
}
</style>
