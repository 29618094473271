<template>
  <vxe-table border stripe max-height="300px" :data="productList">
    <vxe-column field="productName" title="礼品名称" show-overflow></vxe-column>
    <vxe-column field="productPrice" title="礼品单价" show-overflow></vxe-column>

  </vxe-table>
</template>

<script>
export default {
  props: {
    value: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value(newData, oldData) {
      const tempArr = [];
      for (const item of newData) {
        tempArr.push({
          productPrice: item.productPrice,
          productName: item.productName,
        });
      }
      this.productList = tempArr;
    },
  },
  data() {
    return {
      productList: [],
    };
  },
};
</script>
